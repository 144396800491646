/* eslint-disable no-use-before-define */
export const GET_DETAIL_USER_PENDING = "GET_DETAIL_USER_PENDING";
export const GET_DETAIL_USER_SUCCESS = "GET_DETAIL_USER_SUCCESS";
export const GET_DETAIL_USER_FAILED = "GET_DETAIL_USER_FAILED";

export const GET_LIST_USER_PENDING = "GET_LIST_USER_PENDING";
export const GET_LIST_USER_SUCCESS = "GET_LIST_USER_SUCCESS";
export const GET_LIST_USER_FAILED = "GET_LIST_USER_FAILED";

export const GET_DETAIL_RECEIVER_PENDING = "GET_DETAIL_RECEIVER_PENDING";
export const GET_DETAIL_RECEIVER_SUCCESS = "GET_DETAIL_RECEIVER_SUCCESS";
export const GET_DETAIL_RECEIVER_FAILED = "GET_DETAIL_RECEIVER_FAILED";
